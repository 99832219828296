import { ModuleBolloCalculator } from "../../../contentful/PageModularModule";
import React from "react";
import { useTranslation } from "../../../app";
import { dataLayerEvents } from "../../SeoAndAnalytics/GoogleAnalytics/dataLayerEvents";
import BolloComponent from "@carfax/web-ui/components/BolloComponent";

export interface BolloCalculatorModule {
  module: ModuleBolloCalculator;
}

const BolloCalculatorModule: React.FC<BolloCalculatorModule> = ({ module }) => {
  const { t } = useTranslation(["common"]);

  const successEvent = () => {
    dataLayerEvents.submitBolloCalculationEvent({ event: "calculation_success" });
  };

  const errorEvent = (errorMessage: string) => {
    dataLayerEvents.submitBolloCalculationEvent({ event: "calculation_error", errorMessage: errorMessage });
  };

  return (
    <div>
      <BolloComponent
        heading={module.heading}
        subtitle={module.subtitle}
        information={module.information}
        isHeadingH2={module.isHeadingH2}
        regionText={t("bolloComponent.regionText")}
        regionPlaceholder={t("bolloComponent.regionPlaceholder")}
        euroNormText={t("bolloComponent.euroNormText")}
        euroNormPlaceholder={t("bolloComponent.euroNormPlaceholder")}
        enginePowerText={t("bolloComponent.enginePowerText")}
        enginePowerPlaceholder={t("bolloComponent.enginePowerPlaceholder")}
        calculateText={t("bolloComponent.calculateText")}
        summaryText={t("bolloComponent.summaryText")}
        errorMessage={t("bolloComponent.errorMessage")}
        dataLayerSuccessEvent={() => successEvent()}
        dataLayerErrorEvent={(errorMessage: string) => errorEvent(errorMessage)}
      />
    </div>
  );
};

export default BolloCalculatorModule;
