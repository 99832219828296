import React from "react";
import { styles } from "./BolloComponent.style";
import BolloCalculator from "./BolloCalculator";

export interface BolloComponentProps {
  regionText: string;
  euroNormText: string;
  enginePowerText: string;
  calculateText: string;
  summaryText: string;
  heading: string;
  subtitle: string;
  information: string;
  enginePowerPlaceholder: string;
  regionPlaceholder: string;
  euroNormPlaceholder: string;
  errorMessage: string;
  isHeadingH2?: boolean;
  dataLayerErrorEvent: (errorMessage: string) => void;
  dataLayerSuccessEvent: () => void;
}

export const BolloComponent: React.FC<BolloComponentProps> = ({
  heading,
  subtitle,
  information,
  regionText,
  euroNormText,
  enginePowerText,
  calculateText,
  summaryText,
  enginePowerPlaceholder,
  regionPlaceholder,
  euroNormPlaceholder,
  errorMessage,
  isHeadingH2,
  dataLayerSuccessEvent,
  dataLayerErrorEvent,
}) => {
  return (
    <div css={styles.root()}>
      <div css={styles.textWrapper()}>
        {isHeadingH2 ? <h2>{heading}</h2> : <h1>{heading}</h1>}
        <div css={styles.subtitle()}>{subtitle}</div>
        <div css={styles.information()}>{information}</div>
      </div>
      <BolloCalculator
        regionText={regionText}
        euroNormText={euroNormText}
        enginePowerText={enginePowerText}
        calculateText={calculateText}
        summaryText={summaryText}
        enginePowerPlaceholder={enginePowerPlaceholder}
        regionPlaceholder={regionPlaceholder}
        euroNormPlaceholder={euroNormPlaceholder}
        errorMessage={errorMessage}
        dataLayerSuccessEvent={dataLayerSuccessEvent}
        dataLayerErrorEvent={dataLayerErrorEvent}
      />
      <div css={styles.informationMobile()}>{information}</div>
    </div>
  );
};
