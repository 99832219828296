import { useState, useEffect } from "react";

export const useErrorHandler = (
  regionName: string,
  euroNorm: { name: string; value: number },
  enginePower: number,
  regionPlaceholder: string,
  euroNormPlaceholder: string
) => {
  const [regionError, setRegionError] = useState(false);
  const [enginePowerError, setEnginePowerError] = useState(false);
  const [euroNormError, setEuroNormError] = useState(false);
  const [validationPerformed, setValidationPerformed] = useState(false);

  const handleInputError = (inputValue, placeholderValue, setErrorFunction) => {
    if (inputValue === placeholderValue || inputValue === 0 || !inputValue) {
      setErrorFunction(true);
      return true;
    } else {
      setErrorFunction(false);
      return false;
    }
  };

  useEffect(() => {
    if (validationPerformed) {
      validateFields();
    }
  }, [regionName, euroNorm.name, enginePower]);

  const validateFields = (dataLayerEvent?: (euroNorm: boolean, enginePower: boolean, region: boolean) => void) => {
    const regionError = handleInputError(regionName, regionPlaceholder, setRegionError);
    const euroNormError = handleInputError(euroNorm.name, euroNormPlaceholder, setEuroNormError);
    const enginePowerError = handleInputError(enginePower, 0, setEnginePowerError);
    if (dataLayerEvent) {
      dataLayerEvent(euroNormError, enginePowerError, regionError);
    }
  };
  return { regionError, enginePowerError, euroNormError, validateFields, setValidationPerformed };
};
