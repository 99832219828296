import { css } from "@emotion/react";
import { spacing, colors, fontSizes } from "../../../core";

export const styles = {
  root: () =>
    css({
      width: "100%",
      maxWidth: "528px",
      maxHeight: "372px",
      backgroundColor: colors.white.hex,
      boxShadow: "0 25px 50px 0 rgba(0,0,0,0.1)",
    }),
  wrapper: () =>
    css({
      padding: spacing.baseUnitMobile2,
    }),
  summary: () =>
    css({
      background: `rgba(${colors.green.rgba}, 0.1)`,
      display: "flex",
      justifyContent: "space-between",
      fontWeight: "bold",
      padding: spacing.baseUnitMobile2,
    }),
  button: () =>
    css({
      marginTop: spacing.baseUnitMobile1,
      width: "100%",
    }),
  taxValue: () =>
    css({
      fontSize: fontSizes.heading.H5.desktop,
    }),
  option: () =>
    css({
      cursor: "pointer",
      padding: spacing.baseUnitMobile1,
      "&:hover": {
        background: colors.disabledGrey.hex,
      },
    }),
};
