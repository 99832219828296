import { packagesNaming } from "domain/Preview/ComposePreview/Packages/utils/packages-naming";
import type { CheckoutStoreTrackingData, RecordCheckTrackingData } from "./dataLayerECommerceEvents.types";
import { dataLayerUtil } from "./utils/dataLayerUtil";

const submitViewItemEvent = (
  itemName: string,
  currency: string,
  priceIncludingTax: number,
  recordCheckTrackingData: RecordCheckTrackingData = {}
): void => {
  const { reportDataTotal = undefined, vinreg = undefined, reportCombination = undefined } = recordCheckTrackingData;

  const eventParameters = {
    event: "view_item",
    ecommerce: {
      currency: currency,
      value: priceIncludingTax,
      items: [
        {
          item_name: packagesNaming.resolvePackageNameForTracking(itemName),
          price: priceIncludingTax,
          quantity: 1,
          item_variant: reportDataTotal,
          item_brand: dataLayerUtil.getVinregType(vinreg),
          item_category: reportCombination,
        },
      ],
    },
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(eventParameters);
};

const submitAddToCartEvent = (
  itemName: string,
  currency: string,
  priceIncludingTax: number,
  recordCheckTrackingData: RecordCheckTrackingData = {}
): void => {
  const { reportDataTotal = undefined, vinreg = undefined, reportCombination = undefined } = recordCheckTrackingData;

  const eventParameters = {
    event: "add_to_cart",
    ecommerce: {
      currency: currency,
      value: priceIncludingTax,
      items: [
        {
          item_name: packagesNaming.resolvePackageNameForTracking(itemName),
          price: priceIncludingTax,
          quantity: 1,
          item_variant: reportDataTotal,
          item_brand: dataLayerUtil.getVinregType(vinreg),
          item_category: reportCombination,
        },
      ],
    },
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(eventParameters);
};

const submitBeginCheckoutEvent = (
  checkoutStoreTrackingData: CheckoutStoreTrackingData,
  recordCheckTrackingData: RecordCheckTrackingData = {}
): void => {
  const { currency, price, itemName } = checkoutStoreTrackingData;
  const { reportDataTotal = undefined, vinreg = undefined, reportCombination = undefined } = recordCheckTrackingData;

  const eventParameters = {
    event: "begin_checkout",
    ecommerce: {
      currency: currency,
      value: price,
      items: [
        {
          item_name: packagesNaming.resolvePackageNameForTracking(itemName),
          price: price,
          quantity: 1,
          item_variant: reportDataTotal,
          item_brand: dataLayerUtil.getVinregType(vinreg),
          item_category: reportCombination,
        },
      ],
    },
  };
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(eventParameters);
};

export const dataLayerECommerceEvents = {
  submitViewItemEvent,
  submitAddToCartEvent,
  submitBeginCheckoutEvent,
};
