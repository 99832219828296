import React, { useState, useRef, useEffect } from "react";
import { styles } from "./SelectInput.style";

interface SelectProps
  extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  label?: string;
  message?: string;
  hasError?: boolean;
  customFrontIcon?: React.ReactNode;
  isShort?: boolean;
  placeholder?: string;
  testId?: string;
  hideAfterSelect?: boolean;
}

export const SelectInput: React.FC<React.PropsWithChildren<SelectProps>> = (props) => {
  const {
    label,
    children,
    message,
    id,
    disabled,
    hasError,
    customFrontIcon,
    placeholder,
    isShort,
    testId,
    hideAfterSelect,
  } = props;
  const [showSelectSpace, setShowSelectSpace] = useState(false);
  const selectInputRef = useRef(null);

  const handleGlobalClick = (event: MouseEvent) => {
    if (selectInputRef.current && !selectInputRef.current.contains(event.target as Node)) {
      setShowSelectSpace(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleGlobalClick);
    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, []);

  return (
    <div ref={selectInputRef} css={styles.root(isShort)} data-testid={testId}>
      <div
        css={[styles.form(), hasError ? styles.withError : "", disabled ? styles.disabled : ""]}
        onClick={() => !disabled && setShowSelectSpace(!showSelectSpace)}
      >
        <fieldset tabIndex={0} css={styles.content(isShort, showSelectSpace)}>
          {customFrontIcon && <div css={styles.frontIcon}>{customFrontIcon}</div>}
          <div css={styles.inputContainer}>
            <label css={styles.labelText(!!customFrontIcon)} id={id}>
              {label}
            </label>
            <div css={styles.placeholderText(isShort)}>{placeholder}</div>
            <div css={styles.selectArrow(isShort, showSelectSpace)} />
          </div>
        </fieldset>
        {!isShort && <div css={styles.info(!!message)}>{message}</div>}
      </div>
      {showSelectSpace && (
        <div css={styles.selectSpace(isShort)} onClick={() => hideAfterSelect && setShowSelectSpace(false)}>
          {children}
        </div>
      )}
    </div>
  );
};
